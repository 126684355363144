<template>
  <div class="backgroud">
    <van-nav-bar title="账号登录" />
    <div style="margin-top: 30px;">
      <van-form @submit="login">
        <van-cell-group inset>
          <van-field v-model="loginParam.userName" name="用户名" label="用户名" placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]" />
          <van-field v-model="loginParam.password" type="password" name="密码" label="密码" placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]" />

          <van-field v-model="loginParam.kaptcha" clearable center placeholder="请输入验证码" name="verifycode"
            :rules="[{ required: true, message: '请输入验证码', trigger: 'onBlur' }]">
            <template #right-icon>

              <van-image width="100" height="40" :src="kaptchaImgSrc" @click="getKaptchaImg">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>

            </template>
          </van-field>

        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" :loading="isLoading">
            登录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { showSuccessToast } from 'vant'
export default {
  data() {
    return {
      loginParam: {
        userName: '',
        password: '',
        kaptcha: ''
      },
      kaptchaImgSrc: '',
      isLoading: false
    }
  },
  methods: {
    login() {
      this.isLoading = true
      this.$api.login.login(this.loginParam).then(
        res => {
          this.isLoading = false
          showSuccessToast('登录成功')
          localStorage.setItem('access_token', res.data.access_token)
          localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
          localStorage.setItem('permissions', res.data.permission)
          this.$router.push('/')
        }
      ).catch(
        () => {
          this.isLoading = false
        }
      )
    },
    getKaptchaImg() {
      this.kaptchaImgSrc = ''
      this.$api.login.getKaptchaImg().then(
        res => {
          this.kaptchaImgSrc = window.URL.createObjectURL(res.data)
          // console.log(res)
        })
    }
  },
  mounted() {
    this.getKaptchaImg()
  }
}
</script>

<style scoped>
.backgroud {
  width: 100%;
  height: 100%;
  background-color: #f2f3f5;
}
</style>