<template>
  <div class="backgroud">
    <van-nav-bar title="功能大厅" right-text="注销" @click-right="logout" />
    <van-cell-group title="门市管理" inset>

      <van-grid>
        <van-grid-item icon="records-o" text="补货计划" to="/restock" />
        <van-grid-item icon="photo-o" text="建设中" />
        <van-grid-item icon="photo-o" text="建设中" />
        <van-grid-item icon="photo-o" text="建设中" />
      </van-grid>
    </van-cell-group>
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  methods: {
    logout() {
      this.$api.login.logout().then(
        () => {
          this.$router.push('/login')
        }
      )
    }
  }
}

</script>


<style scoped>
.backgroud {
  width: 100%;
  height: 100%;
  background-color: #f2f3f5;
}
</style>