import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant'
import router from './router'
import api from './api' // 导入api接口
// import Vue from 'vue'
import 'vant/lib/index.css'




// createApp(App).use(router).mount('#app')
const app = createApp(App)
app.config.globalProperties.$api = api

app.use(router)
app.use(Vant);
// app.config.globalProperties.$toast = Vant.Toast;
app.mount('#app')

