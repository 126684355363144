/**
 * api接口的统一出口
 */
// 登录接口
import login from '@/api/login'
// 其他模块的接口……

import restock from '@/api/restock'

// import goodsBaseDataManage from '@/api/goodsBaseDataManage'

// import platformManage from '@/api/platformManage'

// 导出接口
export default {
  login, restock
}