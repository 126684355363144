/**
 * login模块接口列表
 */

import base from './base' // 导入接口域名列表
import axios from '@/utils/axios' // 导入http中创建的axios实例
import axios4 from '@/utils/axios4' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const login = {
  // 发送验证码
  sendVCode(param) {
    return axios.post(`${base.api}/login/sendVCode`, qs.stringify(param))
  },

  // 登录
  login(param) {
    return axios.post(`${base.api}/login2`, qs.stringify(param))
  },
  logout() {
    return axios.post(`${base.api}/logout`)
  },
  testLoginState() {
    return axios.post(`${base.api}/test`, qs.stringify({data: '123'}))
  },
  getKaptchaImg() {
    console.log(process.env.VUE_APP_URL)
    return axios4.get(`${base.api}/login/kaptcha`)
  }
}

export default login