<template>
  
    <div id="app">
      
      <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style lang="scss">
html {
  padding: 0px;
  margin: 0px;
  background-color: #f2f3f5;
  body {
    padding: 0px;
    margin: 0px;
    #app {
      font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 0px;
      margin: 0px;

    }
  }
}
</style>
