<template>
  <audio controls="controls" ref="audioRef" style="display: none;">
    <source :src="audioSrc" type="audio/mp3" />
  </audio>
  <van-nav-bar :left-text="lefttext" :left-arrow="showBack" :title="navTitle" @click-left="onClickLeft" fixed
    placeholder @click-right="onClickRight">
    <template #right>
      <van-icon name="add" size="20" v-show="showNavRight" />
    </template>
  </van-nav-bar>
  <div v-show="showList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="loadList"
        :immediate-check="false">
        <van-cell v-for="item in restockList" :key="item" :title="item.billNo" :value="item.createUser"
          :label="item.insertTime" is-link @click="clickBill(item)">
          <template #title>
            <span class="custom-title" style="margin-right: 4px; vertical-align: middle;">{{item.billNo }}</span>
            <van-tag type="success" v-show="item.printTimes > 0 ? true : false">已打印</van-tag>
          </template>
          <template #label>
            {{item.insertTime}}
          </template>
        </van-cell>

      </van-list>
    </van-pull-refresh>
    <van-back-top />
  </div>

  <div v-show="showInsert">
    <van-form @submit="insertGoods">
      <van-cell-group inset style="margin-top: 10px;">
        <van-field v-model="barcode" label="条码" placeholder="请输入条码" />
      </van-cell-group>
    </van-form>
    <van-pull-refresh v-model="itemListRefreshing" @refresh="onRefreshItem" style="margin-top: 10px">
      <van-list v-model:loading="itemListLoading" :finished="itemListFinished" finished-text="没有更多了"
        @load="loadGoodsList" :immediate-check="false">
        <van-swipe-cell v-for="item in restockItemList" :key="item">
          <van-cell :title="item.goodsName" :value="item.goodsID" :label="item.barcode" />
          <template #right>
            <van-button square type="danger" text="删除" style="height: 100%;" @click="deleteItemClick(item.goodsID)" />

          </template>
        </van-swipe-cell>
      </van-list>
    </van-pull-refresh>
    <van-dialog v-model:show="showMultiList" title="请选择" style="width: 90%;" :show-confirm-button="false"
      :show-cancel-button="true" @cancel="barcode = ''">
      <van-list>
        <van-cell v-for="item in multiList" :key="item" :title="item.goodsName"
          @click="onClickMultiItem(item.goodsID)" />
      </van-list>
    </van-dialog>

  </div>

</template>
<script>
import { showSuccessToast } from 'vant'
export default {
  data() {
    return {
      restockList: [],
      restockItemList: [],
      multiList: [],
      listLoading: false,
      itemListLoading: false,
      finished: false,
      itemListFinished: false,
      itemListRefreshing: false,
      refreshing: false,
      showList: true,
      showInsert: false,
      showNavRight: true,
      selectBillNo: '',
      showBack: true,
      navTitle: '补货单列表',
      lefttext: '主页',
      barcode: '',
      audioSrc: '',
      showMultiList: false,
      pageInfo: {
        pageNum: 0,
        pageSize: 15
      },
      itemListPageInfo: {
        pageNum: 0,
        pageSize: 7
      }
    }
  },
  methods: {
    loadList() {
      if (this.refreshing) {
        this.restockList = [];
        this.refreshing = false;
      }
      this.pageInfo.pageNum++
      this.$api.restock.loadBillList(this.pageInfo).then(
        res => {
          this.restockList = this.restockList.concat(res.data.list)

          this.listLoading = false

          if (res.data.total <= this.restockList.length) {
            this.finished = true
          }

        }
      )
    },
    loadGoodsList() {

      if (this.itemListRefreshing) {
        this.restockItemList = []
        this.itemListRefreshing = false
      }

      this.itemListPageInfo.pageNum++
      this.$api.restock.getItemList(this.selectBillNo, this.itemListPageInfo).then(
        res => {
          this.restockItemList = this.restockItemList.concat(res.data.list)
          this.itemListLoading = false

          if (res.data.total <= this.restockItemList.length) {
            this.itemListFinished = true
          }
        }
      )
    },
    onRefresh() {
      this.finished = false
      this.listLoading = true
      this.pageInfo.pageNum = 0
      this.loadList()
    },
    onRefreshItem() {
      this.itemListFinished = false
      this.itemListLoading = true
      this.itemListPageInfo.pageNum = 0
      this.loadGoodsList()
    },
    onClickLeft() {

      if (this.lefttext == '返回列表') {
        this.showList = true
        this.navTitle = '补货单列表'
        this.lefttext = '主页'
        this.showNavRight = true
        this.showInsert = false
      } else {
        history.back()
      }

    },
    onClickRight() {
      const userName = JSON.parse(localStorage.getItem('userInfo')).name
      this.$api.restock.createBill(userName).then(
        () => {
          showSuccessToast('建单成功')
          this.refreshing = true
          this.onRefresh()
        }
      )
    },
    onClickMultiItem(goodsID) {
      const userName = JSON.parse(localStorage.getItem('userInfo')).name
      const item = {
        goodsID: goodsID,
        barcode: this.barcode,
        userName: userName,
        restockBillNo: this.selectBillNo
      }
      this.$api.restock.addDataByGoodsID(item).then(
        () => {
          this.showMultiList = false
          this.barcode = ''
          this.itemListRefreshing = true
          this.onRefreshItem()

          this.audioSrc = require('@/assets/audio/success.wav')
          var audio = this.$refs.audioRef
          audio.load()
          audio.play()
        }
      )
    },
    clickBill(bill) {
      this.showList = false
      this.navTitle = '计划录入'
      this.lefttext = '返回列表'
      this.selectBillNo = bill.billNo
      this.showNavRight = false
      this.showInsert = true
      this.restockItemList = []
      this.itemListFinished = false
      this.itemListLoading = true
      this.loadGoodsList()
    },
    insertGoods() {
      const userName = JSON.parse(localStorage.getItem('userInfo')).name
      if (this.barcode != '') {
        this.$api.restock.addDataByBarcode(this.barcode, this.selectBillNo, userName).then(
          res => {

            var audio
            if (res.msg == '含多条商品数据') {
              this.audioSrc = require('@/assets/audio/error.mp3')
              audio = this.$refs.audioRef
              audio.load()
              audio.play()
              this.multiList = res.data
              this.showMultiList = true
              return
            }
            this.barcode = ''

            this.itemListRefreshing = true
            this.onRefreshItem()

            this.audioSrc = require('@/assets/audio/success.wav')
            // var audio2 = this.$refs.audioRef
            audio = this.$refs.audioRef
            audio.load()
            audio.play()
            console.log(res)
          }
        ).catch(
          (err) => {
            if (err.data.msg == '已存在') {
              this.audioSrc = require('@/assets/audio/error.mp3')
              var audio = this.$refs.audioRef
              audio.load()
              audio.play()
            }
            this.barcode = ''
          }
        )
      }


    },
    deleteItemClick(goodsID) {
      this.$api.restock.deleteItem(this.selectBillNo, goodsID).then(
        () => {
          showSuccessToast('删除成功')
          this.itemListRefreshing = true
          this.onRefreshItem()
        }
      )
    },
  },
  mounted() {

    this.loadList()
  }
}
</script>