// import Vue from 'vue'
import  {createRouter, createWebHistory} from 'vue-router'
import Index from '@/components/views/page_index.vue'
import page404 from '@/components/views/page_404.vue'
import Login from '@/components/views/page_login.vue'
import Restock from '@/components/views/page_restock'

// Vue.use(Router)

const routes = [
  { path: '/', name:'Index', component: Index},
  { path: '/404', name:'404', component: page404},
  { path: '/login', name:'login', component: Login},
  { path: '/restock', name:'restock', component: Restock}
]

const router = createRouter({routes,
  history: createWebHistory()
}  
)

export default router