import base from './base' // 导入接口域名列表
import axios from '@/utils/axios' // 导入http中创建的axios实例
import axio2 from '@/utils/axios2' // 导入http中创建的axios实例
import qs from 'qs'

const resock = {
  loadBillList(pageInfo) {
    return axios.get(`${base.api}/Restock/RestockBill/${pageInfo.pageNum}/${pageInfo.pageSize}`)
  },
  createBill(userName) {
    return axios.post(`${base.api}/Restock/RestockBill`,  qs.stringify({userName: userName}))
  },
  addDataByBarcode(barcode, billNo, userName) {
    return axios.post(`${base.api}/Restock/AddDataByBarcode`,  qs.stringify({barcode:barcode, restockBillNo: billNo, userName: userName}))
  },
  getItemList(billNo,pageInfo) {
    return axios.get(`${base.api}/Restock/RestockItem/${billNo}/${pageInfo.pageNum}/${pageInfo.pageSize}`)
  },
  deleteItem(billNo, goodsID) {
    return axios.delete(`${base.api}/Restock/RestockData`,{data: qs.stringify({billNo: billNo, goodsID: goodsID})})
  },
  addDataByGoodsID(item) {
    return axio2.post(`${base.api}/Restock/RestockData`,item)
  }
}


export default resock